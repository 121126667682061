import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Rotem Sivan"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Rotem Sivan"
        videoUrl="https://vimeo.com/498101254/105b32c2c3"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"nicholas"}
                fluid={data.rotemSivanImage.childImageSharp.fluid}
              />
            </Box>
          </Box>

          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Rotem Sivan
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and performing artist
            </Text>
            <Box>
              <Link to="https://www.instagram.com/rotemsivanguitar" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/RotemSivanMusic/" as="a">
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Rotem Sivan’s guitar skills are heralded across the globe. The New
              York Times has praised him as “more than a very good young guitar
              player.” Downbeat Magazine has called him “a remarkable talent.”
              And universally beloved music platform, Treble FM, has described
              him as a “bonafide guitar god with rugged Israeli sex appeal and
              skills that make us swoon.” Rotem tours with his band most of the
              year playing in world-renowned festivals and clubs. He has
              performed in countries such as France, Italy, Spain, Germany,
              Austria, Belgium, Poland, Slovenia, Mexico, Chile, Costa Rica,
              Puerto Rico, Israel, UK, Canada and India among others, and has
              given workshops around the world in top universities and schools.
            </Text>
            <Text level="2">
              He has performed at many clubs and festivals around the world such
              as Polanco Festival, Chutzpah Festival, Marseille Festival, Nice
              Festival, Exposure Festival, Montreux Festival, Utopia Music
              Festival, Bern Festival, Ottawa Festival and Sonora Festival to
              name a few.
            </Text>
            <Text level="2">
              Rotem has developed a strong online presence with over 44k
              followers on Instagram alone. The album "Antidote" (2017) & “My
              Favorite Monster” (2018) were both charting on Billboard Jazz, and
              # 1 iTunes iTunes charts and top 100 on iTunes.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Rotem Sivan" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    rotemSivanImage: file(
      relativePath: { eq: "rotemSivan-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
